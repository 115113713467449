<template>
  <div class="page">
    <div class="row">
      <div class="col">
        <h1 class="font-lg">Контроль доступа</h1>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col pa-5 server my-5 mx-3">
        <h4>Вы на тестовом сервере. <a href="https://sostaff.io">Перейти на PROD</a></h4>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col d-flex align-center">
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab>Группы</v-tab>
          <v-tab>Права</v-tab>
        </v-tabs>
      </div>
    </div> -->
    <div class="row">
      <div class="col d-flex align-center">
        <!-- <v-tabs-items v-model="tab" class="w-100"> -->
          <Groups />
          <!-- <Permissions /> -->
        <!-- </v-tabs-items> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Groups from '@/components/access/Groups';
// import Permissions from '@/components/access/Permissions';

export default {
  components: {
    Groups,
    // Permissions,
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
  },
  methods: {
    ...mapActions('access', ['loadAll']),
  },
  mounted() {
    this.loadAll();
  },
};
</script>

<style scoped lang="scss">
// @import '~assets/styles/_variables.scss';

.server {
  border: 1px solid #ccc;
  border-radius: 5px;
}

</style>
