<template>
  <div class="w-100">
        <v-card>
            <v-row>
                <v-col :cols="4" class="left-col">
                    <!-- <v-card-text>Выберите группу: </v-card-text> -->
                    <v-list dense class="items-list">
                        <v-list-item-group v-model="curGroup" color="primary" @change="selectGroup">
                            <v-list-item v-for="g in groups" :key="g.id">
                                <v-list-item-content>
                                    <v-list-item-title v-text="g.name"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>
                <v-col :cols="8">
                    <div v-if="this.curGroup>=0">
                      <div class="row ">
                        <div class="col-6 pt-5 align-items-center">
                          Права для группы:
                        </div>
                        <div class="col-6">
                          <v-text-field label="Введите фильтр" prepend-inner-icon="mdi-magnify"
                          class="font-sm mb-3 mr-2" dense hide-details outlined :value="filterPerm"
                          @input="debouncedSetFilterPerm"
                        />
                        </div>
                      </div>
                      <!-- <v-checkbox v-model="selectAll" label="Все права" hide-details class="_ml-9 mt-0 mb-2" /> -->
                      <!-- <v-divider class="mt-2 mb-2"/> -->
                      <!-- <v-treeview v-if="filteredPerms.length"
                          dense
                          class="items-list"
                          item-key="id" item-text="title"
                          :selectable="curGroup!==undefined"
                          selected-color="red"
                          :items="filteredPerms"
                          v-model="curPerms"
                          @input="selectPerms"
                      ></v-treeview> -->
                      <v-data-table v-if="filteredPerms.length"
                          :headers="headers"
                          :items="filteredPerms"
                          :items-per-page="-1"
                          v-model="curPerms"
                          show-select dense fixed-header
                          @item-selected="selectPerms"
                          @toggle-select-all="selectPerms"
                          :custom-filter="filterText"
                          :search="filterPerm"
                          height="65vh"
                        ></v-data-table>
                      <div v-else>
                        Права не найдены
                      </div>
                    </div>
                    <div v-else>
                      Выберите группу
                    </div>
                </v-col>
            </v-row>
            <div class="d-flex justify-center pb-5">
                <v-btn :disabled="!changed" :loading="saving" color="primary" class="pa-5" @click="save">Сохранить</v-btn>
            </div>

        </v-card>
        <br>
        {{curPermsIds}}
        <ConfirmDialog v-model="showDlgSave" text="Сохранить измененные права?<br>
            Все несохраненные данные будут потеряны." okText="Сохранить" @action="save"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ConfirmDialog from '@/components/profgrams/ConfirmDialog';
import { debounce } from 'lodash';

export default {
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      curGroup: 0,
      curPerms: [],
      changed: false,
      saving: false,
      showDlgSave: false,
      filterPerm: '',
      selectAll: false,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Название', value: 'name' },
        { text: 'Код', value: 'codename' },
      ],
    };
  },
  computed: {
    ...mapState('access', ['perms', 'groups']),
    filteredPerms() {
      return this.perms;
      // return this.filterPerm ? this.perms.filter((el) => el.name.toLowerCase().includes(this.filterPerm.toLowerCase())) : this.perms;
    },
    curPermsIds() {
      return this.curPerms.map(el => el.id);
    }
  },
  watch: {
    groups() {
      this.selectGroup(0);
    },
    curGroup() {
      this.filterPerm = '';
    }
    // selectAll(v) {
    //   if (v) {
    //     this.curPerms = this.filteredPerms.map((el) => el.id);
    //   } else {
    //     this.curPerms = [];
    //   }
    // },
  },
  methods: {
    selectGroup(ind) {
      if (ind !== undefined) {
        // this.curPerms = this.groups[ind].permissions;
        this.curPerms = this.filteredPerms.filter(el => this.groups[ind].permissions.includes(el.id));
      }
      this.$nextTick(() => {
        this.changed = false;
      });
    },
    selectPerms() {
      this.changed = true;
    },
    async save() {
      this.saving = true;
      // await this.$store.dispatch('access/saveGroup', { ind: this.curGroup, permissions: this.curPerms });
      // this.groups[ind].permissions = this.curPerms.map(el => el.id)
      const permissions = this.curPerms.map(el => el.id);
      await this.$store.dispatch('access/saveGroup', { ind: this.curGroup, permissions });

      this.saving = false;
      this.changed = false;
    },
    debouncedSetFilterPerm: debounce(function (val) {
      this.filterPerm = val;
    }, 500),

    filterText(value, search) {
      return value != null && search != null && typeof value === 'string' && value.toString().toLocaleUpperCase().includes(search.toLocaleUpperCase());
    },

  },

};
</script>

<style scoped>
.items-list {
    max-height: 50vh;
    overflow: hidden;
    overflow-y: auto;
}

.left-col {
    border-right: 1px solid #ccc;
}

</style>
