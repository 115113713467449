<template>
  <v-dialog v-model="isDialogVisible" max-width="600px">
    <v-card class="pa-8 pa-sm-16">
      <h2>{{title}}</h2>
      <div class="mt-6" v-html="text" />
      <div class="row flex-sm-row-reverse mt-6 mt-sm-12">
        <div class="col-sm-6 text-center">
          <v-btn @click="ok()" class="px-16" color="error" large>{{okText}}</v-btn>
        </div>
        <div class="col-sm-6 pt-0 pa-sm-3 text-center">
          <v-btn @click="isDialogVisible = false" class="px-16" large outlined text>{{cancelText}}</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ConfirmDialog',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: 'Отмена',
    },
    okText: {
      type: String,
      default: 'Ok',
    },
  },
  computed: {
    isDialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('input', false);
        }
      },
    },
  },
  methods: {
    ok() {
      this.$emit('input', false);
      this.$emit('action');
    },
  },
};
</script>

<style scoped>

</style>
